import React, { Component } from "react";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import RenderField from "../../HelperComponents/RenderField/RenderField";
import DefaultButton from "../../HelperComponents/DefaultButton/DefaultButton";
import TransitionedBlock from "../../HelperComponents/TransitionedBlock/TransitionedBlock";
import FormControl from "@material-ui/core/FormControl";
import SelectComponent from "../../HelperComponents/SelectComponent/SelectComponent";

import { getOption } from "../../../helpers/functions";
import logo_auth from "../../../assets/image/logo_auth.svg";

import { login } from '../../../actions/authActions';
import { getStoragesAuth } from '../../../actions/authActions';

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ScoreList: [],
            Score: null
        };
    }

    componentDidMount() {
        const { getStoragesAuth } = this.props;
        localStorage.clear();
        getStoragesAuth().then(res => {
            if (res.payload) {
                let ScoreList = [];
                res.payload.data.map(({ name, id, default_price }) => ScoreList.push({ label: getOption(name), value: id, default_price: default_price }));
                this.setState({ ScoreList })
            }
        });
    };

    submitForm = data => {
        const { login, history } = this.props;
        const { Score } = this.state;
        data.email = data.email.replace(/\s+/g, '');
        data.name = Score.value;
        return login(data).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                localStorage.setItem('token', res.payload.data.token);
                localStorage.setItem('storage_id', Score.value);
                localStorage.setItem('default_price', Score.default_price);
                history.push('/main/shop');
            } else if (res.error.response.data.detail) {
                throw new SubmissionError({ email: res.error.response.data.detail });
            } else if (res.error) {
                throw new SubmissionError({ ...res.error.response.data });
            }
        });
    };

    handleChange = name => event => {
        this.setState({ [name]: event });
    };


    render() {
        const { handleSubmit, submitting, pristine, valid, name } = this.props;
        const { Score, ScoreList } = this.state;
        return (
            <TransitionedBlock>
                <form onSubmit={handleSubmit(this.submitForm)}>
                    <div className="logo_block_auth">
                        <img src={logo_auth} alt="logo_auth" />
                    </div>
                    <div className="title_auth">Авторизация</div>
                    <div>
                        <Field name="email" type="text" component={RenderField} label="Электронная почта" placeholder="Введите почту..." />
                    </div>
                    <div>
                        <Field name="password" type="password" component={RenderField} label="Пароль" placeholder="Введите пароль..." />
                    </div>
                    <div>
                        <FormControl className="select_wrapper">
                            <SelectComponent
                                value={Score}
                                options={ScoreList}
                                change={this.handleChange("Score")}
                                isClearable="false"
                                isSearchable={false}
                                placeholder="Магазин"
                            />
                        </FormControl>
                    </div>
                    <div className="password_reset">
                        <Link className="good_hover" to={`/auth/reset-password/first-step`}>Забыли пароль?</Link>
                    </div>

                    <div className="auth_btn_wrapper">
                        <DefaultButton
                            variant="contained"
                            disabled={submitting || pristine || !valid || !Score}
                            formAction
                        >
                            Войти
                        </DefaultButton>
                    </div>
                </form>
            </TransitionedBlock>
        );
    }
}

const validate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = "Обязательное поле";
    } else if (!values.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i) && !values.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}\s/i)) {
        errors.email = "Неправильный email";
    }
    if (!values.password) {
        errors.password = "Обязательное поле";
    } else if (values.password.length < 8) {
        errors.password = "Минимум 8 символов";
    }
    return errors;
};

Login = reduxForm({
    form: "LoginForm",
    validate
})(Login);

const mapStateToProps = ({ }) => {
    return {
    };
};


const mapDispatchToProps = {
    login,
    getStoragesAuth
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
