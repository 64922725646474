import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import DialogComponent from "../HelperComponents/DialogComponent/DialogComponent";
import Frame_big from "../../assets/image/Frame_big.svg";
import nonAvatart from "../../assets/image/non-avatar.png";
import cartCloseBtn from "../../assets/image/cart_close_btn.svg";
import cartStockWarning from "../../assets/image/cart_stock_warning.svg";
import cartPlus from "../../assets/image/plus.svg";
import cartMinus from "../../assets/image/minus.svg";
import Discount_edit from "../../assets/image/discount_edit.svg";
import DefaultButton from "../HelperComponents/DefaultButton/DefaultButton";
import TransitionedBlock from "../HelperComponents/TransitionedBlock/TransitionedBlock";
import { FormControlLabel, Checkbox, TextField } from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ReactComponent as UncheckIcon } from "../../assets/image/uncheck.svg";
import { ReactComponent as CheckIcon } from "../../assets/image/check.svg";
import { personalInfo } from "../../actions/authActions";

import { Link } from "react-router-dom";
import Dec from "../../assets/image/dec.png";
import Inc from "../../assets/image/inc.png";
import saleBadge from "../../assets/image/sale-badge.svg";
import topSalesBadge from "../../assets/image/top-sales.svg";
import "./Client.scss";

import {
    GetClientInfo,
    sessionEnd,
    setNeedDelivery,
    setAddress,
    setDate,
    sendToPicking
} from "../../actions/clientAuthActions";

import CircularProgress from "@material-ui/core/CircularProgress";
import { cartUpdate } from "../../actions/clientAuthActions";
import { toFixedNoRounding } from "../../helpers/functions";
import { getHeaderDropData, getHeaderDropShop, logout } from "../../actions/authActions";
import { getSearchResults } from "../../actions/searchActions";

class ClientInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            buttonLoad: localStorage.getItem("buttonLoad") || "false",
            consultationStatus: localStorage.getItem("consultationStatus") || "finish",
            cart: localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [],
            discountModal: false,
            is_logged: localStorage.getItem("is_logged"),
            clientInfo: JSON.parse(localStorage.getItem("client_info")),
            loading: false,
            discount_size: JSON.parse(localStorage.getItem("client_info"))
                ? JSON.parse(localStorage.getItem("client_info")).user_discount.toString()
                : null,
            quantityModal: false,
            quantityModalTarget: {},
            number_order: "",
            areYouSure: false
        };
    }

    componentDidMount() {
        const { GetClientInfo } = this.props;
        const { is_logged, clientInfo } = this.state;
        if (is_logged === null || is_logged !== "true" || clientInfo === null) {
            this.setState({ loading: true });
            GetClientInfo(localStorage.getItem("client_phone")).then(res => {
                if (res.payload && res.payload.status && res.payload.status === 200) {
                    this.setState({ loading: false });
                }
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { cartUpdate } = this.props;
        if (prevState.cart !== this.state.cart) {
            localStorage.setItem("cart", JSON.stringify(this.state.cart));
            cartUpdate();
        }
    }

    areYouSureToggle = () => {
        this.setState(({ areYouSure }) => ({ areYouSure: !areYouSure }));
    };

    toggleDialog = () => {
        this.setState(({ open }) => ({
            open: !open
        }));
    };

    endConsult = () => {
        const { sessionEnd } = this.props;
        const { discount_size, cart } = this.state;
        const { deliveryAddress, selectedDate, needDelivery } = this.props;
        localStorage.setItem("buttonLoad", "true");
        this.setState({ buttonLoad: "true" });
        sessionEnd(
            localStorage.getItem("client_phone"),
            localStorage.getItem("storage_id"),
            discount_size,
            cart
                .filter(searchedElement => searchedElement.stock !== 0)
                .map(el => {
                    let obj = {
                        id: el.item.id,
                        price_type: el.priceType,
                        quantity: el.amount > el.stock ? el.stock : el.amount
                    };
                    if (el.discount !== null && el.discount !== 0) obj.discount = el.discount;
                    return obj;
                }),
            cart
                .filter(searchedElement => searchedElement.amount > searchedElement.stock)
                .map(el => ({
                    id: el.item.id,
                    price_type: el.priceType,
                    quantity: el.amount - el.stock
                })),
            needDelivery ? deliveryAddress : "",
            needDelivery ? selectedDate : null
        ).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 200) {
                this.logOut(res.payload.data.number_order);
            } else {
                localStorage.setItem("buttonLoad", "false");
                if (res.error.response.data && res.error.response.data.detail) {
                    this.setState({
                        storageFail: true,
                        storageFailText: res.error.response.data.detail,
                        buttonLoad: "false"
                    });
                } else {
                    this.setState({ storageFail: true, buttonLoad: "false" });
                }
            }
        });
    };

    toggleConsultationStatus = (status, number_order) => {
        const { history } = this.props;
        this.setState({ consultationStatus: status, storageFail: false });
        localStorage.setItem("consultationStatus", status);
        if (status === "start") {
            history.push("/main/shop");
        }
        if (status === "finish") {
            this.toggleDialog();
            localStorage.removeItem("regForm");
            localStorage.setItem("buttonLoad", "false");
            this.setState({ buttonLoad: "false", number_order: number_order });
            localStorage.setItem("cart", JSON.stringify([]));
        }
    };

    logOut = number_order => {
        const { history, setAddress, setDate, setNeedDelivery } = this.props;
        localStorage.removeItem("client_phone");
        localStorage.removeItem("client_info");
        localStorage.setItem("is_logged", "false");
        this.toggleConsultationStatus("finish", number_order);
        setAddress("");
        setDate(null);
        setNeedDelivery(false);
    };

    changeDiscount = (status, event) => {
        let { clientInfo, discount_size } = this.state;

        switch (status) {
            case "toggle":
                this.setState(({ discountModal }) => ({ discountModal: !discountModal }));
                break;
            case "dec":
                this.setState(({ discount_size }) => ({
                    discount_size: +discount_size - 1 >= 0 ? Number(discount_size) - 1 : Number(discount_size)
                }));
                break;
            case "inc":
                this.setState(({ discount_size }) => ({
                    discount_size: +discount_size + 1 <= 20 ? Number(discount_size) + 1 : Number(discount_size)
                }));
                break;
            case "input":
                let newValue = event.target.value.replace(/\D/g, "");
                this.setState({
                    discount_size: newValue === "" ? "" : newValue > 20 ? 20 : newValue < 0 ? 0 : Number(newValue)
                });
                break;
            case "clear":
                this.setState({ discount_size: "" });
                break;
            case "accept":
                let newDiscount = discount_size === "" ? "0" : discount_size;
                localStorage.setItem("client_info", JSON.stringify({ ...clientInfo, user_discount: newDiscount }));
                this.setState({
                    discountModal: false,
                    clientInfo: { ...clientInfo, user_discount: newDiscount },
                    discount_size: newDiscount
                });
                break;
        }
    };

    openQuantityModal = (element, idx) => {
        this.setState({
            quantityModalTarget: element,
            quantityModal: idx
        });
    };

    handlePurchaseValue = (sign, element, idx) => {
        let cart = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [];
        cart[idx].amount = eval(Number(element.amount) + sign + 1);
        this.setState({ cart: cart });
    };

    handleChangeInputValue = (value, element, outOfStock) => {
        let customValue,
            cart = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [],
            index = this.state.quantityModal;
        if (value == "" || value == " ") {
            customValue = "";
        } /* else if (Number(value) > 999) {
            customValue = 999;
        } */ else {
            customValue = Number(value);
        }
        if (Number(value) >= 0) {
            if (outOfStock) {
                cart[index].amount = cart[index].stock + customValue;
            } else {
                cart[index].amount = customValue;
            }
            this.setState({ cart: cart, quantityModalTarget: cart[index] });
        }
    };

    deleteCartItem = event => {
        let cart = localStorage.getItem("cart") ? JSON.parse(localStorage.getItem("cart")) : [],
            type = event.target.dataset.type,
            id = event.target.dataset.id,
            target = cart[id],
            index = id;

        switch (type) {
            case "all":
                cart = cart
                    .map(el =>
                        el.item.has_id_1c === false
                            ? null
                            : el.amount > el.stock
                                ? {
                                    ...el,
                                    amount: el.stock
                                }
                                : el
                    )
                    .filter(el => el !== null);
                break;
            case "in":
                cart = [...cart.slice(0, index), ...cart.slice(index + 1)];
                break;
            case "out":
                if (target.item.has_id_1c === false) {
                    cart = [...cart.slice(0, index), ...cart.slice(index + 1)];
                } else {
                    cart[index].amount = cart[index].stock;
                }
                break;
            default:
                break;
        }
        this.setState({ cart: cart });
    };

    render() {
        const {
            open,
            consultationStatus,
            storageFail,
            storageFailText,
            buttonLoad,
            discountModal,
            clientInfo,
            loading,
            discount_size,
            cart,
            quantityModal,
            quantityModalTarget,
            number_order,
            areYouSure
        } = this.state;
        const {
            handleSubmit,
            needDelivery,
            setNeedDelivery,
            setAddress,
            setDate,
            selectedDate,
            deliveryAddress
        } = this.props;
        if (loading) {
            return null;
        } else {
            let total = toFixedNoRounding(
                cart.reduce(
                    (x, y) => x + (y.item.discount_price ? y.item.discount_price : y.item[y.priceType]) * y.amount,
                    0
                ),
                2
            ),
                discount = toFixedNoRounding(
                    (cart
                        .filter(
                            searchedElement =>
                                searchedElement.stock !== 0 &&
                                !searchedElement.item.discount_price &&
                                !searchedElement.discount
                        )
                        .reduce((x, y) => x + y.item[y.priceType] * (y.amount > y.stock ? y.stock : y.amount), 0) *
                        (!!clientInfo ? clientInfo.user_discount : 1)) /
                    100,
                    2
                ),

                specialDiscount = toFixedNoRounding(
                    cart
                        .filter(searchedElement => searchedElement.stock !== 0 && searchedElement.discount)
                        .reduce(
                            (x, y) =>
                                x +
                                ((y.item[y.priceType] * y.discount) / 100) * (y.amount > y.stock ? y.stock : y.amount),
                            0
                        ),
                    2
                ),
                totalInStock = toFixedNoRounding(
                    cart
                        .filter(
                            searchedElement =>
                                searchedElement.stock !== 0 /* &&
                                searchedElement.item.has_id_1c !== false ||
                                searchedElement.amount > searchedElement.stock &&
                                searchedElement.item.has_id_1c === true */
                        )
                        .reduce(
                            (x, y) =>
                                x + (y.item.has_id_1c
                                    ? y.item.discount_price ? y.item.discount_price : y.item[y.priceType]
                                    : y.item.discount_price ? y.item.discount_price : y.item.price)
                                * (y.amount > y.stock ? y.stock : y.amount),
                            0
                        ),
                    2
                ),
                totalInStockDiscount = toFixedNoRounding(Number(discount) + Number(specialDiscount), 2),
                totalInStockWithDiscount = toFixedNoRounding(Number(totalInStock) - Number(totalInStockDiscount), 2),
                totalOutStock = toFixedNoRounding(
                    cart
                        .filter(
                            searchedElement =>
                                //searchedElement.stock !== 0 && (
                                searchedElement.amount > searchedElement.stock /* &&
                                    searchedElement.item.has_id_1c === true) ||
                                searchedElement.item.has_id_1c === false */
                        )

                        .reduce(
                            (x, y) =>
                                x +
                                (y.item.has_id_1c
                                    ? y.item.discount_price ? y.item.discount_price : y.item[y.priceType]
                                    : y.item.discount_price ? y.item.discount_price : y.item.price) *
                                y.amount
                            ,
                            0
                        ),
                    2
                ),

                totalWithDiscount = toFixedNoRounding(Number(totalInStockWithDiscount) + Number(totalOutStock), 2);

            return (
                <TransitionedBlock classes="transitioned_block">

                    <div className={`client_page${consultationStatus === "start" ? " with_cart" : ""}`}>
                        <div className="client_content client_info">
                            <div>
                                <div className="name">{`${clientInfo && clientInfo.first_name} ${clientInfo && clientInfo.last_name}`}</div>
                                <div className="phone">{clientInfo && clientInfo.phone_number}</div>
                            </div>
                            <div>
                                <div className="mail">Электронная почта</div>
                                <div className="mail_info">{clientInfo && clientInfo.email || "-"}</div>
                            </div>
                            <div className={`discount_info_wrapper`}>
                                {/* <img src={Discount_edit} alt="" onClick={() => this.changeDiscount("toggle")} /> */}
                                <div>
                                    <div className="discount">Cкидка</div>
                                    <div className="discount_info">{clientInfo && clientInfo.user_discount}%</div>
                                </div>
                            </div>
                            {consultationStatus === "start" && (
                                <>
                                    <div className={`delivery_info wrapper${needDelivery ? " need-delivery" : ""}`}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={<UncheckIcon />}
                                                    checkedIcon={<CheckIcon />}
                                                    name="checkedH"
                                                    onChange={() => setNeedDelivery(!needDelivery)}
                                                    checked={needDelivery}
                                                />
                                            }
                                            label="Нужна доставка"
                                            labelPlacement="end"
                                        />
                                    </div>
                                    <div className={`delivery_info form${needDelivery ? " need-delivery" : ""}`}>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <TextField
                                                label="Адрес доставки"
                                                onChange={({ target: { value } }) => setAddress(value)}
                                                value={deliveryAddress}
                                            />
                                            <DateTimePicker
                                                ampm={false}
                                                disablePast
                                                value={selectedDate}
                                                onChange={e => setDate(e)}
                                                label="Время доставки"
                                                format="dd.MM.yyyy HH:mm"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                    <div className="sum_info">
                                        <p>
                                            <span>ТОВАРЫ В НАЛИЧИИ:</span>
                                        </p>
                                        <p>
                                            Итого: <span>{totalInStock} грн</span>
                                        </p>
                                        <p>
                                            Скидка: <span>{totalInStockDiscount} грн</span>
                                        </p>
                                        <p>
                                            Итого(со скидкой): <span>{totalInStockWithDiscount} грн</span>
                                        </p>
                                        {/* <p>Товары под заказ: <span>{totalOutStock} грн</span></p> */}
                                    </div>
                                    <div className="sum_info general">
                                        <p>
                                            <span>ТОВАРЫ ПОД ЗАКАЗ:</span>
                                        </p>
                                        <p> Итого: <span>{totalOutStock} грн</span></p>

                                    </div>
                                </>
                            )}
                            <div>
                                {storageFail ? <span className={`storage_error`}>{storageFailText}</span> : null}
                                {consultationStatus === "start" ? (
                                    <button
                                        disabled={
                                            needDelivery
                                                ? selectedDate === null ||
                                                deliveryAddress === "" ||
                                                buttonLoad === "true"
                                                : buttonLoad === "true"
                                        }
                                        className={`end_session`}
                                        onClick={this.areYouSureToggle}
                                    >
                                        {buttonLoad === "true" && <CircularProgress color="secondary" />}
                                        Завершить консультацию
                                    </button>
                                ) : (
                                    <DefaultButton
                                        variant="contained"
                                        onClick={() => this.toggleConsultationStatus("start")}
                                    >
                                        Начать консультацию
                                    </DefaultButton>
                                )}
                            </div>
                        </div>
                        {consultationStatus === "start" && (
                            <div>
                                <div
                                    className={`client_content client_info cart${cart === null
                                        ? ""
                                        : cart.length > 0 &&
                                            cart.some(searchedElement => searchedElement.stock !== 0)
                                            ? ""
                                            : " empty"
                                        }`}
                                >
                                    <div>
                                        <div className="name">Товары в наличии</div>
                                        {/* {cart !== null && cart.inStock.length > 0 && <button className="clear_cart">Очистить корзину</button>} */}
                                    </div>
                                    {cart !== null &&
                                        cart.length > 0 &&
                                        cart.some(searchedElement => searchedElement.stock !== 0) ? (
                                        <div className="cart-item">
                                            {cart
                                                .filter(searchedElement => searchedElement.stock !== 0)
                                                .map((el, idx) => {
                                                    return (
                                                        <>
                                                            {el.item.has_id_1c === true ?
                                                                <div key={idx} className="cart-item container">
                                                                    <div className="image-wrapper">
                                                                        <img
                                                                            src={
                                                                                el.item.images.length > 0
                                                                                    ? el.item.images[0]
                                                                                    : nonAvatart
                                                                            }
                                                                            alt="Cart item image"
                                                                        />
                                                                    </div>
                                                                    <div className="info-wrapper">
                                                                        <div className="info header">
                                                                            <Link
                                                                                to={`/main/shop/product/${el.item.id}`}
                                                                                className="heading"
                                                                            >
                                                                                {el.item.name}
                                                                                <span className="price-indicator">({el.priceType.includes('retail') ? 'Розничная цена' : 'Оптовая цена'})</span>
                                                                            </Link>
                                                                            <img
                                                                                className="delete"
                                                                                src={cartCloseBtn}
                                                                                alt="Delete cart item"
                                                                                data-type="in"
                                                                                data-id={idx}
                                                                                onClick={this.deleteCartItem}
                                                                            />
                                                                        </div>
                                                                        <div className="info body">
                                                                            <div className="input_add">
                                                                                <img
                                                                                    src={cartMinus}
                                                                                    alt="cartminus"
                                                                                    onClick={e => {
                                                                                        e.stopPropagation();
                                                                                        el.amount > 1 &&
                                                                                            this.handlePurchaseValue(
                                                                                                "-",
                                                                                                el,
                                                                                                idx
                                                                                            );
                                                                                    }}
                                                                                    style={
                                                                                        el.amount <= 1 ? { opacity: "0.3" } : {}
                                                                                    }
                                                                                    className="cart-minus"
                                                                                />
                                                                                <input
                                                                                    value={
                                                                                        el.amount === 0
                                                                                            ? ""
                                                                                            : el.amount > el.stock
                                                                                                ? el.stock
                                                                                                : el.amount
                                                                                    }
                                                                                    onChange={e => {
                                                                                        this.handleChangeInputValue(
                                                                                            e.target.value,
                                                                                            el,
                                                                                            false
                                                                                        );
                                                                                    }}
                                                                                    onClick={e => e.stopPropagation()}
                                                                                    disabled
                                                                                />
                                                                                <img
                                                                                    src={cartPlus}
                                                                                    alt="cartplus"
                                                                                    onClick={e => {
                                                                                        e.stopPropagation();
                                                                                        /* el.amount < 999 && */
                                                                                        this.handlePurchaseValue("+", el, idx);
                                                                                    }}
                                                                                    className="cart-plus"
                                                                                /* style={
                                                                            el.amount >= 999 ? { opacity: "0.3" } : {}
                                                                        } */
                                                                                />
                                                                                <img
                                                                                    src={Discount_edit}
                                                                                    className="edit_btn"
                                                                                    alt=""
                                                                                    onClick={() => this.openQuantityModal(el, idx)}
                                                                                />
                                                                            </div>
                                                                            <div className="price">
                                                                                {(el.item.discount_price || !!el.discount) && (
                                                                                    <span className="discount">
                                                                                        {toFixedNoRounding(
                                                                                            el.item[el.priceType],
                                                                                            2
                                                                                        )}{" "}
                                                                                        грн
                                                                                    </span>
                                                                                )}
                                                                                {el.item.has_id_1c === false ?
                                                                                    <span>
                                                                                        {toFixedNoRounding(
                                                                                            el.item.discount_price || !!el.discount
                                                                                                ? !!el.discount
                                                                                                    ? el.item.price -
                                                                                                    (el.item.price *
                                                                                                        el.discount) /
                                                                                                    100
                                                                                                    : el.item.discount_price
                                                                                                : el.item.price,
                                                                                            2
                                                                                        )}{" "}
                                                                                        грн
                                                                                    </span>
                                                                                    :
                                                                                    <span>
                                                                                        {toFixedNoRounding(
                                                                                            el.item.discount_price || !!el.discount
                                                                                                ? !!el.discount
                                                                                                    ? el.item[el.priceType] -
                                                                                                    (el.item[el.priceType] *
                                                                                                        el.discount) /
                                                                                                    100
                                                                                                    : el.item.discount_price
                                                                                                : el.item[el.priceType],
                                                                                            2
                                                                                        )}{" "}
                                                                                        грн
                                                                                    </span>
                                                                                }

                                                                                {el.item.sale ? (
                                                                                    <img
                                                                                        src={saleBadge}
                                                                                        alt="sale"
                                                                                        style={{ marginLeft: "40px" }}
                                                                                    />
                                                                                ) : el.item.top_sales ? (
                                                                                    <img
                                                                                        src={topSalesBadge}
                                                                                        alt="topSales"
                                                                                        style={{ marginLeft: "40px" }}
                                                                                    />
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                        {el.amount > el.stock && (
                                                                            <div className="info footer">
                                                                                <img
                                                                                    src={cartStockWarning}
                                                                                    alt="Cart stock warning"
                                                                                />
                                                                                <span>
                                                                                    Только {el.stock} в наличии на складе.{" "}
                                                                                    {el.amount - el.stock} - под заказ!
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                : null}
                                                        </>
                                                    );
                                                })}
                                        </div>
                                    ) : (
                                        <p>Нет товаров.</p>
                                    )}
                                </div>
                                {cart.filter(searchedElement =>
                                    searchedElement.item.has_id_1c === false ||
                                    (searchedElement.amount > searchedElement.stock && searchedElement.item.has_id_1c === true)).length !== 0 ?
                                    <div className='client_content client_info cart'>
                                        <div>
                                            <div className="name">Товары под заказ</div>
                                            {cart !== null &&
                                                cart.some(
                                                    searchedElement => searchedElement.amount > searchedElement.stock
                                                ) && (
                                                    <button
                                                        className="clear_cart"
                                                        data-type="all"
                                                        onClick={this.deleteCartItem}
                                                    >
                                                        Очистить
                                                    </button>
                                                )}
                                        </div>
                                        <div className="cart-item">
                                            {cart
                                                .map((el, idx) => (
                                                    <>
                                                        {el.item.has_id_1c === false || (el.amount > el.stock && el.item.has_id_1c === true) ?
                                                            <div key={idx} className="cart-item container">
                                                                <div className="image-wrapper">
                                                                    <img
                                                                        src={
                                                                            el.item.images.length > 0
                                                                                ? el.item.images[0]
                                                                                : nonAvatart
                                                                        }
                                                                        alt="Cart item image"
                                                                    />
                                                                </div>
                                                                <div className="info-wrapper">
                                                                    <div className="info header">
                                                                        <Link
                                                                            to={`/main/shop/product/${el.item.id}`}
                                                                            className="heading"
                                                                        >
                                                                            {el.item.name}
                                                                            <span className="price-indicator">({el.priceType.includes('retail') ? 'Розничная цена' : 'Оптовая цена'})</span>
                                                                        </Link>
                                                                        <img
                                                                            className="delete"
                                                                            src={cartCloseBtn}
                                                                            alt="Delete cart item"
                                                                            data-type="out"
                                                                            data-id={idx}
                                                                            onClick={this.deleteCartItem}
                                                                        />
                                                                    </div>
                                                                    <div className="info body">
                                                                        <div className="input_add">
                                                                            <img
                                                                                src={cartMinus}
                                                                                alt="cartminus"
                                                                                onClick={e => {
                                                                                    e.stopPropagation();
                                                                                    el.amount > 1 &&
                                                                                        this.handlePurchaseValue(
                                                                                            "-",
                                                                                            el,
                                                                                            idx
                                                                                        );
                                                                                }}
                                                                                style={el.amount <= 1 ? { opacity: "0.3" } : {}}
                                                                                className="cart-minus"
                                                                            />
                                                                            <input
                                                                                value={
                                                                                    el.item.has_id_1c === false ?
                                                                                        el.amount === 0 ? "" : el.amount :
                                                                                        el.amount - el.stock === 0 ? "" : el.amount - el.stock
                                                                                }
                                                                                onChange={e => {
                                                                                    this.handleChangeInputValue(
                                                                                        e.target.value,
                                                                                        el,
                                                                                        true
                                                                                    );
                                                                                }}
                                                                                onClick={e => e.stopPropagation()}
                                                                                disabled
                                                                            />
                                                                            <img
                                                                                src={cartPlus}
                                                                                alt="cartplus"
                                                                                onClick={e => {
                                                                                    e.stopPropagation();
                                                                                    /* el.amount < 999 && */
                                                                                    this.handlePurchaseValue("+", el, idx);
                                                                                }}
                                                                                className="cart-plus"
                                                                            /*  style={
                                                                             el.amount >= 999 ? { opacity: "0.3" } : {}
                                                                         } */
                                                                            />
                                                                            <img
                                                                                src={Discount_edit}
                                                                                className="edit_btn"
                                                                                alt=""
                                                                                onClick={() => this.openQuantityModal(el, idx)}
                                                                            />
                                                                        </div>
                                                                        <div className="price">
                                                                            {el.item.discount_price && (
                                                                                <span className="discount">
                                                                                    {el.item[el.priceType]} грн
                                                                                </span>
                                                                            )}
                                                                            <span>
                                                                                {el.item.discount_price
                                                                                    ? el.item.discount_price
                                                                                    : el.item[el.priceType]}{" "}
                                                                                грн
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : null
                                                        }
                                                    </>
                                                ))}
                                        </div>
                                    </div>
                                    : null
                                }
                            </div>
                        )}
                        <DialogComponent open={areYouSure} onClose={this.areYouSureToggle}>
                            <div className="dialog_client are_you_sure">
                                <div>Завершить консультацию</div>
                                <p>Вы не забыли предложить акцию, услуги, озвучить наши преимущества клиенту?</p>
                                <div className="button_container">
                                    <DefaultButton
                                        variant="contained"
                                        onClick={this.endConsult}
                                        disabled={buttonLoad === "true"}
                                    >
                                        {buttonLoad === "true" && <CircularProgress color="secondary" />}
                                        OK
                                    </DefaultButton>
                                    <div className="back_btn">
                                        <DefaultButton variant="contained_grey" onClick={this.areYouSureToggle}>
                                            Отменить
                                        </DefaultButton>
                                    </div>
                                </div>
                            </div>
                        </DialogComponent>
                        <DialogComponent
                            open={open}
                            onClose={() => {
                                localStorage.removeItem("regForm");
                                this.props.history.push("/main/client/login");
                            }}
                        >
                            <div className="dialog_client">
                                <img src={Frame_big} alt="Frame_big" />
                                <div>
                                    Сессия успешно <br /> завершена
                                </div>
                                {number_order !== "" && <div>Номер заказа: {number_order}</div>}
                                <DefaultButton
                                    variant="contained"
                                    onClick={() => {
                                        localStorage.removeItem("regForm");
                                        this.props.history.push("/main/client/login");
                                    }}
                                >
                                    Авторизовать нового клиента
                                </DefaultButton>
                            </div>
                        </DialogComponent>
                        <DialogComponent open={discountModal} onClose={() => this.changeDiscount("toggle")}>
                            <form onSubmit={handleSubmit(() => this.changeDiscount("accept"))}>
                                <div className="dialog_client">
                                    <div>Изменение размера скидки</div>
                                    <div className={`change_discount_wrapper`}>
                                        <img
                                            src={Dec}
                                            alt=""
                                            onClick={() => this.changeDiscount("dec")}
                                            style={discount_size <= 0 ? { opacity: "0.3" } : {}}
                                        />

                                        <input
                                            type="text"
                                            onFocus={() => this.changeDiscount("clear")}
                                            onChange={e => this.changeDiscount("input", e)}
                                            min={0}
                                            max={20}
                                            value={discount_size}
                                        />

                                        <img
                                            src={Inc}
                                            alt=""
                                            onClick={() => this.changeDiscount("inc")}
                                            style={discount_size >= 20 ? { opacity: "0.3" } : {}}
                                        />
                                    </div>

                                    <div className="button_container">
                                        <DefaultButton
                                            variant="contained"
                                            onClick={() => this.changeDiscount("accept")}
                                        >
                                            Сохранить
                                        </DefaultButton>
                                        <div className="back_btn">
                                            <DefaultButton
                                                variant="contained_grey"
                                                onClick={() => this.changeDiscount("toggle")}
                                            >
                                                Отменить
                                            </DefaultButton>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </DialogComponent>
                        <DialogComponent
                            open={quantityModal !== false}
                            onClose={() => this.setState({ quantityModal: false })}
                        >
                            <form onSubmit={handleSubmit(() => this.setState({ quantityModal: false }))}>
                                <div className="dialog_client quantity">
                                    <div className={`modal_title`}>Изменение количества товара</div>
                                    <div className="info body">
                                        <div className="input_add">
                                            <input
                                                defaultValue={quantityModalTarget.amount}
                                                onChange={e => {
                                                    this.handleChangeInputValue(
                                                        Number(e.target.value),
                                                        quantityModalTarget
                                                    );
                                                }}
                                                onClick={e => e.stopPropagation()}
                                            />
                                        </div>
                                    </div>
                                    {quantityModalTarget.item && quantityModalTarget.item.has_id_1c !== false ?
                                        <>
                                            {quantityModalTarget.amount > quantityModalTarget.stock && (
                                                <div className="info footer">
                                                    <img src={cartStockWarning} alt="Cart stock warning" />
                                                    <span>
                                                        Только {quantityModalTarget.stock} в наличии на складе.{" "}
                                                        {quantityModalTarget.amount - quantityModalTarget.stock} - под заказ!
                                                    </span>
                                                </div>
                                            )}
                                        </>
                                        : null
                                    }
                                    <div className="button_container">
                                        <DefaultButton variant="contained" formAction>
                                            Сохранить
                                        </DefaultButton>
                                    </div>
                                </div>
                            </form>
                        </DialogComponent>
                    </div>
                </TransitionedBlock>
            );
        }
    }
}

const validate = values => {
    const errors = {};
    if (!values.email) {
        errors.email = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2, 5}$/i.test(values.email)) {
        errors.email = "Invalid email";
    }
    if (!values.password) {
        errors.password = "Required";
    } else if (values.password.length < 8) {
        errors.password = "Must be 8 characters or more";
    }
    return errors;
};

ClientInfo = reduxForm({
    form: "ClientInfoForm",
    validate
})(ClientInfo);

const mapStateToProps = ({ client }) => {
    return {
        clientInfo: client.client_info,
        needDelivery: client.needDelivery,
        selectedDate: client.selectedDate,
        deliveryAddress: client.deliveryAddress
    };
};

const mapDispatchToProps = {
    GetClientInfo,
    sessionEnd,
    cartUpdate,
    setNeedDelivery,
    setAddress,
    setDate
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientInfo);
