import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import chevron_red from "../../../assets/image/chevron_red.svg";
import "./AllInfo.scss";
import yes from "../../../assets/image/yes.svg";
import no from "../../../assets/image/no.svg";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import Frame_big from "../../../assets/image/Frame_big.svg";
import nonAvatart from "../../../assets/image/non-avatar.png";
import cartPlus from "../../../assets/image/plus.svg";
import cartMinus from "../../../assets/image/minus.svg";
import cart from "../../../assets/image/cart-img.svg";
import cartAdd from "../../../assets/image/cart-add.svg";
import { makeRequest } from "../../../actions/productsActions";
import { cartUpdate } from "../../../actions/clientAuthActions";
import { storageFilter, toFixedNoRounding } from "../../../helpers/functions";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { ReactComponent as UncheckIcon } from "../../../assets/image/uncheck.svg";
import { ReactComponent as CheckIcon } from "../../../assets/image/check.svg";
import DefaultButton from "../../HelperComponents/DefaultButton/DefaultButton";
import AdjustmentDialog from "../AdjustmentDialog/AdjustmentDialog";
import { getStoragesAuth } from "../../../actions/authActions";

class AllInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openSupplier: false,
            activePic: 0,
            clientInfo: localStorage.getItem("client_info") ? JSON.parse(localStorage.getItem("client_info")) : false,
            lightbox: false,
            amount: 1,
            descriptionValue: "",
            addedToCart: false,
            specialDiscount: false,
            specialDiscountRate: null,
            adjustmentDialog: false,
            allStorages: []
        };
    };

    componentDidMount() {
        //.dialog_product.lightbox img
        const { getStoragesAuth } = this.props;
        getStoragesAuth().then(res => {
            if (res.payload) {
                const allStorages = storageFilter(res.payload.data);
                this.setState({ allStorages })
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentProd.id !== this.props.currentProd.id) {
            this.setState({ addedToCart: false });
        }
    }

    toggleDialog = () => {
        this.setState(({ open }) => ({
            open: !open
        }));
    };
    toggleDialogSupplier = () => {
        this.setState(({ openSupplier }) => ({
            openSupplier: !openSupplier,
            amount: 1,
            descriptionValue: "",
        }));
    };
    toggleAdjustmentDialog = () => {
        this.setState(({ adjustmentDialog }) => ({
            adjustmentDialog: !adjustmentDialog,
        }));
    };
    toggleLightBox = () => {
        this.setState(({ lightbox }) => ({ lightbox: !lightbox }))
    }

    selectPic = idx => {
        this.setState({ activePic: idx });
    }

    Request = () => {
        const { makeRequest, changeErrorText, currentProd: { id }, accountInfo, phone_number, comment, quantity } = this.props;
        const { clientInfo } = this.state;
        makeRequest({
            phone_number: !clientInfo.phone_number ? '' : clientInfo.phone_number,
            quantity: this.state.amount,
            comment: this.state.descriptionValue,
        }, id).then(res => {
            if (res.payload && res.payload.status && res.payload.status === 201) {
                this.toggleDialogSupplier()
                this.setState({
                    amount: 1,
                    descriptionValue: "",
                });

            } else {
                if (res.error.response.data.detail) {
                    changeErrorText(res.error.response.data.detail)
                }
            }
        });
    }

    handlePurchaseValue = sign => {
        if (sign === "+") {
            this.setState(({ amount }) => ({ amount: amount + 1, addedToCart: false }))
        } else {
            this.setState(({ amount }) => ({ amount: amount - 1, addedToCart: false }))
        }
    };

    handleChangeInputValue = value => {
        let customValue;
        if (value == "" || value == " ") {
            customValue = '';
        } /* else if (+value > 999) {
            customValue = 999;
        } */ else {
            customValue = parseInt(value);
        }
        if (value >= 0) {
            this.setState({ amount: customValue, addedToCart: false });
        }
    };

    addToCart = inStock => {
        let cart = localStorage.getItem('cart')
            ? JSON.parse(localStorage.getItem('cart'))
            : [],
            { cartUpdate, currentProd, storages } = this.props,
            { amount, specialDiscount, specialDiscountRate } = this.state,
            sum = storages.reduce(
                (x, y) => x + Number(y.quantity),
                0
            ),
            cartItem = {
                priceType: localStorage.getItem("default_price"),
                amount: Number(amount),
                stock: sum,
                item: currentProd,
            };
        if (specialDiscount) cartItem.discount = Number(specialDiscountRate);
        // if item is allready exist and if this priceType is same to current
        if (cart.some(searchedElement => searchedElement.item.id === currentProd.id && searchedElement.priceType === localStorage.getItem("default_price"))) {
            cart[cart.findIndex(searchedElement => searchedElement.item.id === currentProd.id && searchedElement.priceType === localStorage.getItem("default_price"))].amount += amount;
            if (specialDiscount && !cart[cart.indexOf(cart.find(searchedElement => searchedElement.item.id === currentProd.id))].discount) {
                cart[cart.indexOf(cart.find(searchedElement => searchedElement.item.id === currentProd.id && searchedElement.priceType === localStorage.getItem("default_price")))].discount = Number(specialDiscountRate);
            }
        } else {
            cart.push(cartItem);
        }

        localStorage.setItem("cart", JSON.stringify(cart));
        cartUpdate();
        this.setState({ addedToCart: true });
    }

    render() {
        const {
            open,
            activePic,
            clientInfo,
            lightbox,
            amount,
            addedToCart,
            specialDiscount,
            specialDiscountRate,
            openSupplier,
            descriptionValue,
            adjustmentDialog,
            allStorages
        } = this.state;
        const { accountInfo, currentProd, storages, chars, data, showAllChars, isStorError, storageFailText, visiblePrice } = this.props;

        const filteredStorages = storageFilter(storages);

        return (
            <div className="all_info">
                <div className="all_info_block">
                    <div className="icon_product_wrapper">
                        <div className="mini_icon">
                            {currentProd.images.length > 0 ? currentProd.images.map((el, idx) => (
                                <div className={activePic === idx ? "active" : ""} onClick={() => this.selectPic(idx)}
                                    key={idx}><img src={el} alt="product_big" /></div>
                            ))
                                :
                                <div className={'active'} onClick={() => this.selectPic(0)}><img src={nonAvatart} alt="product_big" /></div>
                            }
                        </div>
                        <div className="big_icon"><img onClick={this.toggleLightBox} src={currentProd.images.length > 0 ? currentProd.images[activePic] : nonAvatart} alt="product_big" /></div>
                    </div>
                    <div className="info_wrapper">
                        {(!!currentProd.discount_price) || (clientInfo && clientInfo.user_discount !== 0)
                            ? currentProd.availability !== '' ?
                                <div>
                                    <div className="info_yes"><img src={yes} alt="yes" />{currentProd.availability}</div>
                                </div>
                                :
                                <div>
                                    {!currentProd.tablet_availability ?
                                        <div className="info_no"><img src={no} alt="no" />Нет в наличии</div>
                                        :
                                        <div className="info_yes"><img src={yes} alt="yes" />В наличии</div>
                                    }
                                </div>
                            : null}



                        {/* 
                        <div className="price">
                                    <span>Цена для клиента</span>
                                    <p>{currentProd.retail_price} грн</p>
                                </div>
                                 */}
                        {
                            !!currentProd.discount_price ?
                                <Fragment>
                                    <div className="price discount_price">
                                        <span>Акционная цена</span>
                                        <p>{currentProd.discount_price} грн</p>
                                    </div>
                                    <div className="price_client">
                                        <div>
                                            <span>{visiblePrice.includes('retail') ? 'Розничная' : 'Оптовая'} цена</span>
                                            <p>{currentProd[visiblePrice]} грн</p>
                                        </div>
                                        <div>
                                            <span>Экономия</span>
                                            <p>{toFixedNoRounding(currentProd[visiblePrice] - currentProd.discount_price, 2)} грн</p>
                                        </div>
                                    </div>
                                </Fragment>
                                : clientInfo && clientInfo.user_discount !== 0 ?
                                    <Fragment>
                                        <div className="price discount_price">
                                            <span>Цена для клиента</span>
                                            <p>{toFixedNoRounding(specialDiscount && specialDiscountRate !== null && Number(specialDiscountRate) !== 0
                                                ? currentProd[visiblePrice] - currentProd[visiblePrice] * specialDiscountRate / 100
                                                : currentProd[visiblePrice] - (currentProd[visiblePrice] / 100 * clientInfo.user_discount), 2)} грн</p>
                                        </div>
                                        <div className="price_client">
                                            <div>
                                                <span>{visiblePrice.includes('retail') ? 'Розничная' : 'Оптовая'} цена</span>
                                                <p>{toFixedNoRounding(currentProd[visiblePrice], 2)} грн</p>
                                            </div>
                                            <div>
                                                <span>Экономия</span>
                                                <p>{toFixedNoRounding(specialDiscount && specialDiscountRate !== null && Number(specialDiscountRate) !== 0
                                                    ? currentProd[visiblePrice] * specialDiscountRate / 100
                                                    : currentProd[visiblePrice] / 100 * clientInfo.user_discount, 2)} грн</p>
                                            </div>
                                        </div>
                                    </Fragment>
                                    :
                                    <div className="price">
                                        <span>Цена{
                                            currentProd.availability !== '' ?
                                                <div>
                                                    <div className="info_yes"><img src={yes} alt="yes" />{currentProd.availability}</div>
                                                </div>
                                                :
                                                <div>
                                                    {!currentProd.tablet_availability ?
                                                        <div className="info_no"><img src={no} alt="no" />Нет в наличии</div>
                                                        :
                                                        <div className="info_yes"><img src={yes} alt="yes" />В наличии</div>
                                                    }
                                                </div>
                                        }</span>
                                        {currentProd.has_id_1c ?
                                            <p>{toFixedNoRounding(specialDiscount && specialDiscountRate !== null && Number(specialDiscountRate) !== 0
                                                ? currentProd[visiblePrice] - currentProd[visiblePrice] * specialDiscountRate / 100
                                                : currentProd[visiblePrice], 2)} грн
                                            </p>
                                            :
                                            <p>{toFixedNoRounding(specialDiscount && specialDiscountRate !== null && Number(specialDiscountRate) !== 0
                                                ? currentProd.price - currentProd.price * specialDiscountRate / 100
                                                : currentProd.price, 2)} грн
                                            </p>
                                        }
                                    </div>
                        }
                        <div className="availability">
                            {!currentProd.tablet_availability && <div className="descriptions">Товар отсутствует</div>}
                            {currentProd.tablet_availability && !currentProd.discount_price &&
                                <div className="special_discount">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                icon={<UncheckIcon />}
                                                checkedIcon={<CheckIcon />}
                                                name="checkedH"
                                                onChange={() => this.setState(({ specialDiscount }) => ({ specialDiscount: !specialDiscount }))}
                                            />
                                        }
                                        label="Ручная скидка"
                                        labelPlacement="end"
                                    />
                                    <div className={`inner${specialDiscount ? ' open' : ''}`}>
                                        <p className="line">Прибыль: {specialDiscount && specialDiscountRate !== null && Number(specialDiscountRate) !== 0
                                            ? `${toFixedNoRounding((currentProd[visiblePrice] - currentProd[visiblePrice] * specialDiscountRate / 100) - currentProd.purchase_price, 2)} грн.`
                                            : '-'
                                        }</p>
                                        <div className="line">Процент скидки:
                                            <input
                                                type="number"
                                                max={100}
                                                min={0}
                                                value={specialDiscountRate}
                                                onChange={({ target: { value } }) => this.setState({
                                                    specialDiscountRate: value < 0
                                                        ? 0
                                                        : value > 100
                                                            ? 100
                                                            : value
                                                })}
                                            />
                                            %</div>
                                        <span className="error">{
                                            (specialDiscountRate && Number(specialDiscountRate) > 100) || (specialDiscountRate && Number(specialDiscountRate) < 0)
                                                ? 'Процент ручной скидки должен быть в рамках от 0 до 100'
                                                : (currentProd[visiblePrice] - currentProd[visiblePrice] * specialDiscountRate / 100) < currentProd.limit_retail_price
                                                    ? `Максимально допустимая скидка ${toFixedNoRounding(100 - (currentProd.limit_retail_price * 100 / currentProd[visiblePrice]), 2)}%`
                                                    : ''
                                        }</span>
                                    </div>
                                </div>
                            }
                            <div className="add_block">
                                <div className="input_add">
                                    <img
                                        src={cartMinus}
                                        alt="cartminus"
                                        onClick={e => {
                                            e.stopPropagation();
                                            amount > 1 && this.handlePurchaseValue("-");
                                        }}
                                        style={amount <= 1 ? { opacity: "0.3" } : {}}
                                        className="cart-minus"
                                    />
                                    <input
                                        value={amount === 0 ? '' : amount}
                                        onChange={e => {
                                            this.handleChangeInputValue(e.target.value);
                                        }}
                                        onClick={e => e.stopPropagation()}
                                    />
                                    <img
                                        src={cartPlus}
                                        alt="cartplus"
                                        onClick={e => {
                                            e.stopPropagation();
                                            /* amount < 999 && */ this.handlePurchaseValue("+");
                                        }}
                                        className="cart-plus"
                                        style={amount >= 999 ? { opacity: "0.3" } : {}}
                                    />
                                </div>
                                {addedToCart ?
                                    <div className="cart-added-wrapper">
                                        <img className={'added_img'} src={cartAdd} alt="cartadded" />
                                    </div>
                                    :
                                    <button
                                        className={`cart-add-wrapper${!currentProd.tablet_availability ? ' cart-no-items-add' : ''}${amount === '' || amount === 0 ? ' disabled' : ''}`}
                                        onClick={e => {
                                            e.stopPropagation();
                                            amount !== '' && amount !== 0 && this.addToCart(currentProd.tablet_availability ? 'inStock' : 'outStock');
                                        }}
                                        disabled={
                                            (specialDiscountRate && Number(specialDiscountRate) > 100)
                                            || (specialDiscountRate && Number(specialDiscountRate) < 0)
                                            || (currentProd[visiblePrice] - currentProd[visiblePrice] * specialDiscountRate / 100) < currentProd.limit_retail_price
                                        }
                                    >
                                        <img src={cart} alt="cartadd" />
                                        {currentProd.tablet_availability ? 'В корзину' : 'Под заказ'}
                                    </button>
                                }
                            </div>
                            {currentProd.tablet_availability &&
                                <>{
                                    filteredStorages !== null && filteredStorages.length > 0 ?
                                        <table className="availability_table">
                                            <tr>
                                                <th>Наличие на складах</th>
                                                <th>Cтелаж</th>
                                                <th>Ячейка</th>
                                            </tr>
                                            {filteredStorages.map((el, idx) => (
                                                <tr className="row" key={idx}>
                                                    <td>{el.name}<span>({el.quantity} ед.)</span></td>
                                                    <td>{el.rack || '-'}</td>
                                                    <td>{el.cell || '-'}</td>
                                                </tr>
                                            ))}

                                        </table>
                                        :
                                        <div className="availability">
                                            <div className="descriptions" >Наличие на складах</div>
                                            <div className="block">
                                                <span>Нет на складах</span>
                                            </div>
                                        </div>
                                }</>
                            }

                            <div className="all_info__btn-group">
                                <button className="all_info__outline-btn" type="button"
                                    onClick={this.toggleDialogSupplier}>
                                    Подать заявку снабженцу
                                </button>
                                {currentProd.has_id_1c && (
                                    <button className="all_info__outline-btn" type="button"
                                        onClick={this.toggleAdjustmentDialog}>
                                        Корректировка остатков
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="specifications">
                        <div className="title_block">Характеристики</div>
                        <div className="info_wrapper">
                            {chars.map((el, idx) => (
                                idx + 1 <= 5 && (
                                    <div className="block" key={idx}>
                                        <span>{el.name}</span>
                                        <p>{el.formatted_values}</p>
                                    </div>
                                )))}
                            {chars.length > 5 &&
                                <div className="add_info" onClick={showAllChars}>Все характеристики <img src={chevron_red} alt="chevron_red" /></div>
                            }
                        </div>
                    </div>
                </div>
                <DialogComponent
                    open={open}
                    onClose={this.toggleDialog}
                >
                    <div className="dialog_product">
                        <img src={Frame_big} alt="Frame_big" />
                        <div>Запрос успешно <br /> сформирован</div>
                    </div>
                </DialogComponent>

                <DialogComponent
                    open={lightbox}
                    onClose={this.toggleLightBox}
                >
                    <div className="dialog_product lightbox">
                        <div className="img">
                            <img src={currentProd.images.length > 0 ? currentProd.images[activePic] : nonAvatart} alt="Frame_big" />
                        </div>
                    </div>
                </DialogComponent>

                <DialogComponent
                    open={openSupplier}
                    onClose={this.toggleDialogSupplier}
                >
                    <div className="dialog_supplier">
                        <div className="title">Заявка снабженцу</div>
                        <div className='descriptions'>Количество товара:</div>

                        <div className="add_block">
                            <div className="input_add">
                                <img
                                    src={cartMinus}
                                    alt="cartminus"
                                    onClick={e => {
                                        e.stopPropagation();
                                        amount > 1 && this.handlePurchaseValue("-");
                                    }}
                                    style={amount <= 1 ? { opacity: "0.3" } : {}}
                                    className="cart-minus"
                                />
                                <input
                                    value={amount === 0 ? '' : amount}
                                    onChange={e => {
                                        this.handleChangeInputValue(e.target.value);
                                    }}
                                    onClick={e => e.stopPropagation()}
                                />
                                <img
                                    src={cartPlus}
                                    alt="cartplus"
                                    onClick={e => {
                                        e.stopPropagation();
                                        /* amount < 999 && */ this.handlePurchaseValue("+");
                                    }}
                                    className="cart-plus"
                                    style={amount >= 999 ? { opacity: "0.3" } : {}}
                                />
                            </div>
                        </div>

                        <div className="descriptions">Комментарий менеджера</div>
                        <textarea
                            name="comment"
                            onChange={(e) =>
                                this.setState({
                                    descriptionValue:
                                        e.target.value,
                                })
                            }
                            type="number"
                            value={descriptionValue}
                        />
                        {isStorError ? <span className={`storage_error`}>{storageFailText}</span> : null}

                        <div className='btn_dialog'>
                            <DefaultButton
                                variant="contained"

                                onClick={this.Request}
                                disabled={
                                    (specialDiscountRate && Number(specialDiscountRate) > 100)
                                    || (specialDiscountRate && Number(specialDiscountRate) < 0)
                                    || (currentProd[visiblePrice] - currentProd[visiblePrice] * specialDiscountRate / 100) < currentProd.limit_retail_price
                                }
                            >
                                Отправить
                            </DefaultButton>
                            <DefaultButton
                                variant="contained_grey"

                                onClick={this.toggleDialogSupplier}
                            >
                                Отменить
                            </DefaultButton>
                        </div>

                    </div>
                </DialogComponent>
                <AdjustmentDialog
                    open={adjustmentDialog}
                    onClose={this.toggleAdjustmentDialog}
                    productId={currentProd.id}
                    allStorages={allStorages}
                />
            </div>
        );
    }
}


const mapStateToProps = ({ auth }) => {
    return {
        accountInfo: auth.account,
        visiblePrice: auth.visiblePrice
    };
};

const mapDispatchToProps = {
    makeRequest,
    cartUpdate,
    getStoragesAuth
};

export default connect(mapStateToProps, mapDispatchToProps)(AllInfo);
