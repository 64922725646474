import { AUTH } from "../constants";

const INITIAL_STATE = {
    account: {
        first_name: "Безымянный",
        last_name: "Безымянный",
        email: "sample@some-mail.com",
        photo: null,
        role: "Менеджер",
        data: {},
        shopData: {}
    },
    visiblePrice: localStorage.getItem('default_price') || 'retail_price'
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case AUTH.GET_PERSONAL_INFO_SUCCESS:
            localStorage.setItem("is_logged", "false");
            if (localStorage.getItem("cart") === null) localStorage.setItem("cart", "[]");
            return { ...state, account: action.payload.data };
        case AUTH.GET_PERSONAL_INFO_FAIL:
            return {
                ...state,
                account: {
                    error: true
                }
            };
        case AUTH.GET_DROP_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload.data
            };
        case AUTH.GET_DROP_SHOP_SUCCESS:
            return {
                ...state,
                shopData: action.payload.data
            };
        case AUTH.SET_VISIBLE_PRICE:
            localStorage.setItem('default_price', action.payload)
            return {
                ...state,
                visiblePrice: action.payload
            }
        default:
            return state;
    }
}
