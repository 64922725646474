import React from "react";
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import "./Toastify.scss";

const Toastify = ({text}) => {

    const tost = toast.warn(text, {
        toastId: 'goal',
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
    });

    return (
        <div className="toastify_custom" >
            <ToastContainer/>
        </div>
    );
};

export default Toastify;
